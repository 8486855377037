import React, { useState } from 'react';
import { css } from '@emotion/react';
import Colors from '../../../types/colors';

export interface ToggleButtonProps {
  toggleButton: boolean;
  handleToggleBtn: () => void;
}

const ToggleButton = ({ toggleButton, handleToggleBtn }: ToggleButtonProps) => {
  const wrapper = css`
    display: flex;
    vertical-align: center;
    align-items: center;
    padding-right: 21px;
  `;
  const toggle = css`
    display: flex;
    background-color: ${Colors['Grey_01_bg']};
    height: 15px;
    width: 35px;
    border-radius: 30px;
  `;
  const toggleLeft = css`
    height: 20px;
    width: 20px;
    background-color: ${Colors['white_regular']};
    border-radius: 20px;
    margin: -2px 0px 0px 0px;
    box-shadow: 0px 0px 5px 0px ${Colors['Grey_03_regular']};
  `;

  const toggleRight = css`
    height: 20px;
    width: 20px;
    background-color: ${Colors['Primary_100_basic']};
    border-radius: 20px;
    margin: -2px 0px 0px 15px;
    box-shadow: 0px 0px 5px 0px ${Colors['Grey_03_regular']};
  `;

  return (
    <div css={wrapper}>
      <div onClick={handleToggleBtn} css={toggle}>
        {toggleButton ? <div css={toggleRight} /> : <div css={toggleLeft} />}
      </div>
    </div>
  );
};

export default ToggleButton;
