import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MoreMessageListView from './more-message-list.view';
import useViewportHeight from '../../../../hooks/useViewportHeight';

import { EtcApi } from '../../../../apis/v1/etc.api';
import useAuthentication from '../../../../store/useAuthentication';

const MoreMessageList = () => {
  const { authentication } = useAuthentication();

  const etcApi = new EtcApi(process.env.REACT_APP_ENV !== 'dev', authentication.accessToken, authentication.deviceId);
  const viewportHeight = useViewportHeight();
  const navigate = useNavigate();
  const [messagesData, setMessagesData] = useState<any>('');
  const [filter, setFilter] = useState<'unread' | 'all'>('all');
  const height = viewportHeight - 185;
  const filteredMessages = messagesData?.dataList?.filter((message: { isRead: boolean }) => filter === 'all' || !message.isRead);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (messagesData.dataList?.length !== undefined && authentication.accessToken.length !== 0) {
      setIsLoading(false);
    }
  }, [messagesData, authentication.accessToken]);
  useEffect(() => {
    if (authentication.accessToken && authentication.deviceId) {
      const fetchData = async () => {
        try {
          const { data }: any = await etcApi.getMessagesListAsync();

          setMessagesData(data);
        } catch (error) {
          // Handle error
          // await window.flutter_inappwebview.callHandler('goErrorPage', '');
        }
      };

      fetchData();
    }
  }, [authentication.accessToken, authentication.deviceId]);

  const onChange = (e: { target: { value: string } }) => {
    setFilter(e.target.value as 'unread' | 'all');
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const props = {
    handleGoBack,
    filter,
    onChange,
    filteredMessages,
    height,
    isLoading,
  };
  return <MoreMessageListView {...props} />;
};

export default MoreMessageList;
