import { AxiosResponse } from 'axios';
import { AxiosConfig, axiosErrorExceptionAsync, AxiosRequest } from '../axios.config';
import { fromByteArray } from 'base64-js';

export type GetReportParamRequest = {
  type: string;
};

export type GetResponse = {
  messageList: any[];
  vocList: any[];
};

export class ReportAPI extends AxiosConfig {
  private _prefix = '/v1';
  private _url = `${this._prefix}/report`;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(useBaseURL?: boolean, token?: string, device?: string) {
    super(useBaseURL, token, device);
  }

  /**
   * 09. 리포트 API - 측정도구 목록 조회
   * @see https://chc-app-api.huraydev.net/swagger-ui/index.html
   * @version 1.0.0
   */
  public getSurveysAsync = async (): Promise<AxiosResponse<any> | AxiosResponse<unknown, any>> => {
    try {
      return await this.instance.get<any>(`${this._url}/surveys`);
    } catch (error) {
      return axiosErrorExceptionAsync(error);
    }
  };

  /**
   * 09. 리포트 API - 리포트 상세 조회
   * @see https://chc-app-api.huraydev.net/swagger-ui/index.html
   * @version 1.0.0
   */
  public getDetailAsync = async ({ type }: AxiosRequest<any>): Promise<AxiosResponse<any> | AxiosResponse<unknown, any>> => {
    try {
      return await this.instance.get<any>(`${this._url}/detail/${type}`);
    } catch (error) {
      return axiosErrorExceptionAsync(error);
    }
  };

  /**
   *
   * 09. 리포트 API - 리포트 PDF 생성
   */
  public getReportPDF = async (): Promise<string> => {
    try {
      const response = await this.instance.get<any>(`${this._url}/pdf`, { responseType: 'arraybuffer' });
      // console.log(response.data, '--> resp data');
      const base64File = fromByteArray(new Uint8Array(response.data));
      // console.log(base64File, 'base64File');
      return base64File;
    } catch (error) {
      console.error(error);
      return ''; // 기본적으로 빈 문자열을 반환합니다.
    }
  };
}
