import React from 'react';
import TypographyView from '../foundation/typography.view';
import { css } from '@emotion/react';
import Colors from '../../../types/colors';
const headers = ['측정지표', '결과'];

interface TableData {
  data?: any;
  selectedYear: any;
}

const TableB = ({ data, selectedYear }: TableData) => {
  const wrapper = css`
    background-color: ${Colors.white_regular};
    margin-bottom: 35px;
  `;

  const tableStyle = css`
    border-collapse: collapse;
    width: 100%;
  `;

  const headerWrapper = css`
    background-color: ${Colors.Grey_01_bg};
    border-radius: 10px !important;
  `;

  const headStyle = css`
    padding: 5px 10px;
    text-align: center;
    width: 50%;
  `;

  const rowStyle = css`
    //padding: 15px 0px;
    text-align: center;
  `;

  const dashedLineStyle = css`
    border-bottom: 1.3px dashed ${Colors.Grey_02_line};
  `;

  const typography = (color?: string) => css`
    display: flex;
    margin: 35px auto;
    justify-content: center;
    color: ${color};
  `;

  function renderHorizontalLine(condition: boolean) {
    return (
      condition && (
        <tr>
          <td colSpan={headers.length}>
            <div css={dashedLineStyle} />
          </td>
        </tr>
      )
    );
  }

  return (
    <div css={wrapper}>
      <table css={tableStyle}>
        <thead>
          <tr css={headerWrapper}>
            {headers.map((header, index) => {
              const isFirstHeader = index === 0;
              const borderRadiusStyles = isFirstHeader ? { borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' } : { borderTopRightRadius: '10px', borderBottomRightRadius: '10px' };

              return (
                <th key={index} css={[headStyle, borderRadiusStyles]}>
                  <TypographyView bold={true} fontSize={16} children={header} />
                </th>
              );
            })}
          </tr>
        </thead>

        <tbody>
          {data[selectedYear]?.map((row: any, rowIndex: number) => (
            <React.Fragment key={rowIndex}>
              {Object.entries(row).map(([key, value]: any) => {
                let cellText = '';
                let cellValue = value;
                let hr = false;
                switch (key) {
                  case 'value':
                    cellText = cellValue ?? '';
                    cellValue = row.color?.toLowerCase() === 'green' ? '좋음' : row.color?.toLowerCase() === 'orange' ? '중간' : '나쁨';
                    break;
                  case 'height':
                    cellText = '키';
                    cellValue = cellValue ? `${cellValue}cm` : '';
                    hr = true;
                    break;
                  case 'weight':
                    cellText = '몸무게';
                    cellValue = cellValue ? `${cellValue}kg` : '';
                    hr = true;
                    break;
                  case 'bmi':
                    cellText = 'BMI';
                    cellValue = cellValue ? `${cellValue} (${row.bmiType})` : '';
                    break;
                  default:
                    return null;
                }

                return (
                  <React.Fragment key={key}>
                    <tr>
                      <td css={rowStyle}>
                        <TypographyView fontSize={16} children={cellText} />
                      </td>
                      <td css={rowStyle}>
                        <TypographyView fontSize={16} children={cellValue} cssStyles={typography(row.color)} />
                      </td>
                    </tr>
                    {renderHorizontalLine(hr)}
                    {renderHorizontalLine(rowIndex !== data?.graph?.length - 1)}
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableB;
