import React from 'react';
import { css } from '@emotion/react';
import MessageView from '../../../atoms/foundation/message.view';
import NoMessageView from '../../../atoms/alert/no-message.view';
import { ImageEmptyMessage } from '../../../../assets/images/svg';
import MESSAGES from '../../../../constants/messages';

interface MessagesProps {
  data?: any;
  height?: number

}

const Messages = ({ data,height }: MessagesProps) => {

  const wrapper = css`
    overflow-y: scroll;
    scroll-behavior: smooth;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  `;

  return (
    <div css={wrapper}>
      {data?.length > 0 ? (
        data.map((message: { sendAt: string; content: string; isRead: boolean; messageId: number }) => (
          <MessageView message={message} key={message.messageId} maxLines={3} height={height} to={`/more/messages/${message.messageId}`} />
        ))
      ) : (
        <NoMessageView icon={ImageEmptyMessage} text={MESSAGES.INFORMATION.EMPTY_MESSAGE} height={'85vh'} />
      )}
    </div>
  );
};

export default Messages;
