import React, { ReactElement } from 'react';
import { css } from '@emotion/react';
import Colors from '../../../types/colors';
import BarChart from '../../atoms/chart/bar-chart';
import TableB from '../../atoms/chart/tableB';
import TableC from '../../atoms/chart/tableC';
import TableD from '../../atoms/chart/tableD';

interface BarChartSectionViewProps {
  icon?: any;
  moreBtn?: ReactElement<any, any>;
  groupedData?: any;
  iconName?: any;
  iconFontSize: any;
  secondaryFontSize: any;
  data: any;
  handleYearChange: any;
  selectedYear: any;
}

const BarChartSectionView = ({ icon, moreBtn, groupedData, iconName, iconFontSize, secondaryFontSize, data, selectedYear, handleYearChange }: BarChartSectionViewProps) => {
  const wrapper = css`
    display: flex;
    flex-direction: column;
    background-color: ${Colors.white_regular};
    padding: 0 18px;
    margin: 8px;
    border-radius: 15px;
  `;

  const iconWrapper = css`
    display: flex;
    justify-content: space-between;
    margin: 27px 0 15px 0;
  `;
  const iconStyle = css`
    display: flex;
    align-items: center;
    width: max-content;
    font-size: ${iconFontSize}px;
    font-weight: bold;
    span {
      font-size: ${secondaryFontSize}px;
    }
  `;

  const dropDownWrapper = css`
    display: flex;
    align-items: center;
  `;

  const dropdownStyle = css`
    margin-right: 10px;
    padding: 5px;
    width: 100px;
    font-size: 14px;
  `;

  return (
    <div css={wrapper}>
      <div css={iconWrapper}>
        <div css={iconStyle}>
          <div>{icon}</div>
          <span>{iconName}</span>
        </div>

        <div css={dropDownWrapper}>
          {data?.graphType !== 'A' && (
            <select css={dropdownStyle} value={selectedYear} onChange={handleYearChange}>
              {Object.keys(groupedData).map((key) => (
                <option key={key} value={parseInt(key)}>
                  {key}차년도
                </option>
              ))}
            </select>
          )}
          {moreBtn}
        </div>
      </div>
      {data.graphType === 'A' && <BarChart BarData={data} />}
      {data.graphType === 'B' && <TableB data={groupedData} selectedYear={selectedYear} />}
      {data.graphType === 'C' && <TableC data={groupedData} selectedYear={selectedYear} />}
      {data.graphType === 'D' && <TableD data={groupedData} selectedYear={selectedYear} />}
    </div>
  );
};

export default BarChartSectionView;
