import React, { useEffect, useState } from 'react';

import SurveyPageView from './survey-page.view';
import { SurveyAPI } from '../../../apis/v1/survey.api';
import useAuthentication from '../../../store/useAuthentication';
import { useNavigate } from 'react-router-dom';

export interface SignInResponse {
  accessToken: string;
}
const SurveyPage = () => {
  const [surveyPageData, setSurveyPageData] = useState([]);
  const { authentication } = useAuthentication();
  const navigate = useNavigate();
  const surveyApi = new SurveyAPI(process.env.REACT_APP_ENV !== 'dev', authentication.accessToken, authentication.deviceId);
  useEffect(() => {
    if (authentication.accessToken && authentication.deviceId) {
      const fetchData = async () => {
        try {
          const { data } = await surveyApi.getListAsync();

          setSurveyPageData(data);
        } catch (error) {
          // await window.flutter_inappwebview.callHandler('goErrorPage', '');
        }
      };

      fetchData();
    }
  }, [authentication.accessToken, authentication.deviceId]);

  const props = {
    surveyPageData,
  };

  return <SurveyPageView {...props} />;
};

export default SurveyPage;
