import { useState } from 'react';
import { css } from '@emotion/react';
import Colors from '../../../../types/colors';
import BarInfoView from '../../common/bar-info-view';
import Icon from '../../../atoms/foundation/icon.view';
import MESSAGES from '../../../../constants/messages';
import iconMap from '../../../../assets/images/iconMap';
import LoadingSpinner from '../../../atoms/foundation/loading-spinner.view';
import useDpi from '../../../../hooks/useDpi';
import NoDashboardDataView from '../../../atoms/alert/no-dashboard-data.view';
import BarChartSection from '../../common/bar-chart-section';

import { ReportAPI } from '../../../../apis/v1/report.api';
import useAuthentication from '../../../../store/useAuthentication';

interface ReportPartBodyViewProps {
  activeButton: string;
  reportDetailData: any;
  buttonData: any;
  isLoading: boolean;
}

const base64ToBlob = (base64: string, mimeType: string): Blob => {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
};

const base64ToUrl = (base64: string, mimeType: string): string => {
  const blob = base64ToBlob(base64, mimeType);
  return URL.createObjectURL(blob);
};

const ReportPartBodyView = ({ activeButton, buttonData, reportDetailData, isLoading }: ReportPartBodyViewProps) => {
  const { subFontSize, iconSize } = useDpi();
  const { authentication } = useAuthentication();

  const reportPDFApi = new ReportAPI(process.env.REACT_APP_ENV !== 'dev', authentication.accessToken, authentication.deviceId);

  const buildDomToImage = () => {
    const fetchPDF = async () => {
      try {
        const base64Data = await reportPDFApi.getReportPDF();
        if (base64Data) {
          console.log(base64Data);
          await window.flutter_inappwebview.callHandler('downloadPDF', base64Data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchPDF();
  };

  const wrapper = css`
    background-color: ${Colors.Grey_01_bg};
    padding-top: 15px;
    overflow-x: hidden;
    height: calc(100vh - 195px);
  `;

  const spinnerWrapper = css`
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    align-items: center;
    margin-top: 130px;
  `;
  const buttonStyle = (type: string) => css`
    border: 1px solid ${Colors['Grey_02_line']};
    width: 65px;
    height: 30px;
    font-weight: 550;
    font-size: ${subFontSize}px;
    color: ${type === 'P' ? Colors['Primary_100_basic'] : Colors['Green_bar']};
  `;

  const hasNonCData = reportDetailData?.dataList?.some((data: any) => data.graphType !== 'C');

  return (
    <div css={wrapper}>
      {reportDetailData && hasNonCData && <BarInfoView buildDomToImage={buildDomToImage} />}
      {isLoading ? (
        <div css={spinnerWrapper}>
          <LoadingSpinner />
        </div>
      ) : activeButton === MESSAGES.BUTTON.ALL ? (
        <>
          {reportDetailData?.dataList?.length !== 0 ? (
            reportDetailData?.dataList?.map((value: { graphCd: string; graphNm: string; maxValue: number; graph: { nthYear: number; color: string; value: number } }, index: number) => {
              const koreanLetters =
                value.graphNm === 'BMI'
                  ? value.graphNm
                  : value.graphNm
                      .split('(')[0] // Remove everything after the opening parenthesis
                      .replace(/[^\uAC00-\uD7AF\u1100-\u11FF\u3130-\u318F]/g, ' ') // Replace non-Korean characters with a space
                      .replace(/\s+/g, ' ') // Replace multiple consecutive spaces with a single space
                      .trim(); // Trim leading and trailing spaces

              return <BarChartSection key={index} data={value} iconName={koreanLetters} icon={<Icon width={`${iconSize}px`} height={`${iconSize}px`} src={iconMap[value.graphCd.trim()]} />} />;
            })
          ) : (
            <NoDashboardDataView />
          )}
        </>
      ) : (
        buttonData
          ?.filter((button: any, index: number) => index !== 0)
          .map((button: { value: string }) => {
            if (activeButton === button?.value) {
              const index = buttonData?.indexOf(button);
              const koreanLetters =
                reportDetailData?.dataList[index - 1]?.graphNm === 'BMI'
                  ? 'BMI'
                  : reportDetailData?.dataList[index - 1]?.graphNm
                      .split('(')[0] // Remove everything after the opening parenthesis
                      .replace(/[^\uAC00-\uD7AF\u1100-\u11FF\u3130-\u318F]/g, ' ') // Replace non-Korean characters with a space
                      .replace(/\s+/g, ' ') // Replace multiple consecutive spaces with a single space
                      .trim(); // Trim leading and trailing spaces
              return (
                <BarChartSection
                  key={index}
                  data={reportDetailData?.dataList[index - 1]}
                  iconName={koreanLetters}
                  icon={<Icon width={`${iconSize}px`} height={`${iconSize}px`} src={iconMap[reportDetailData?.dataList[index - 1]?.graphCd]} />}
                />
              );
            }
          })
      )}
    </div>
  );
};

export default ReportPartBodyView;
