import React from 'react';
import TypographyView from '../foundation/typography.view';
import { css } from '@emotion/react';
import Icon from '../foundation/icon.view';
import { ImageEmptyGraph } from '../../../assets/images/svg';
import MESSAGES from '../../../constants/messages';
import useDpi from '../../../hooks/useDpi';
import Colors from '../../../types/colors';

const NoDashboardDataView = () => {
  const { dashboardImageSize } = useDpi();

  const wrapper = css`
    display: flex;
    flex-direction: column;
    height: 45vh;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 5px;
    background-color: ${Colors.white_regular};
  `;
  return (
    <div css={wrapper}>
      <div>
        <Icon src={ImageEmptyGraph} height={`${dashboardImageSize}px`} width={`${dashboardImageSize}px`} />
      </div>
      <TypographyView color={'Grey_05_subtext'}>{MESSAGES.INFORMATION.EMPTY_DASHBOARD_DATA.TOP} </TypographyView>
      <TypographyView color={'Grey_05_subtext'}>{MESSAGES.INFORMATION.EMPTY_DASHBOARD_DATA.BOTTOM}</TypographyView>
    </div>
  );
};

export default NoDashboardDataView;
