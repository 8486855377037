import React, { useEffect, useState } from 'react';
import MoreTermsDetailView from './more-terms-detail.view';
import { useNavigate, useParams } from 'react-router-dom';
import { TermsAPI } from '../../../../../apis/v1/terms.api';
import useAuthentication from '../../../../../store/useAuthentication';

const MoreTermsDetail = () => {
  const { authentication } = useAuthentication();

  const termsApi = new TermsAPI(process.env.REACT_APP_ENV !== 'dev', authentication.accessToken, authentication.deviceId);
  const navigate = useNavigate();
  const { terms } = useParams();
  const [termsData, setTermsData] = useState<any>();

  useEffect(() => {
    if (authentication.accessToken && authentication.deviceId) {
      const fetchData = async () => {
        try {
          const { data, status } = await termsApi.getAsync();
          // if (data?.code?.includes('ERR_401')) {
          //   await window.flutter_inappwebview.callHandler('goRefresh', '');
          //   // navigate('/index.html');
          // } else if (data?.code?.includes('ERR_500')) {
          //   await window.flutter_inappwebview.callHandler('goErrorPage', '');
          // } else if (data?.code === 'ERR-406-007') {
          //   await window.flutter_inappwebview.callHandler('goRefresh', '');
          // } else if (data?.code === 'ERR-400-000') {
          //   await window.flutter_inappwebview.callHandler('showToastMessage', `${data.message}`);
          //   return;
          //}
          if (terms === 'terms-of-services') {
            setTermsData(data.dataList[0]);
          } else if (terms === 'privacy-and-policy') {
            setTermsData(data.dataList[1]);
          } else {
            setTermsData(data.dataList[2]);
          }
        } catch (error) {
          // await window.flutter_inappwebview.callHandler('goErrorPage', '');
        }
      };
      fetchData();
    }
  }, [authentication.accessToken, authentication.deviceId]);

  const handleBackButton = () => {
    navigate(-1);
  };

  const props = {
    handleBackButton,
    termsData,
  };
  return <MoreTermsDetailView {...props} />;
};

export default MoreTermsDetail;
