import React, { useEffect, useState } from 'react';
import ReportInformationView from './report-information.view';
import { useNavigate, useParams } from 'react-router-dom';
import MESSAGES from '../../../../constants/messages';
import { ReportAPI } from '../../../../apis/v1/report.api';
import useAuthentication from '../../../../store/useAuthentication';

const ReportInformation = () => {
  const [activeButton, setActiveButton] = useState(MESSAGES.BUTTON.ALL);

  const { authentication } = useAuthentication();

  const { surveyType } = useParams();
  const type = surveyType?.replace(/&.*/, '');
  const header = surveyType?.replace(/^.*&/, '');
  const navigate = useNavigate();
  const [reportDetailData, setReportDetailData] = useState<any>('');
  const reportDetailApi = new ReportAPI(process.env.REACT_APP_ENV !== 'dev', authentication.accessToken, authentication.deviceId);
  const buttonData: { text: string; value: React.Key | null | undefined }[] = [{ text: '전체', value: '전체' }];
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (reportDetailData.length !== 0) {
      setIsLoading(false);
    }
  }, [reportDetailData]);
  useEffect(() => {
    if (authentication.accessToken && authentication.deviceId) {
      const fetchData = async () => {
        try {
          const { data } = await reportDetailApi.getDetailAsync({ type: type });
          setReportDetailData(data);
        } catch (error) {
          // await window.flutter_inappwebview.callHandler('goErrorPage', '');
        }
      };
      fetchData();
    }
  }, [authentication.accessToken, authentication.deviceId]);
  const handleButtonClick = (button: any) => {
    setActiveButton(button);
  };
  const handleBack = () => {
    navigate(-1);
  };
  reportDetailData?.dataList?.forEach((item: { graphNm: string }) => {
    const graphNm = item.graphNm.trim();
    const koreanGraphNm = graphNm === 'BMI' ? graphNm : graphNm
      .split('(')[0] // Remove everything after the opening parenthesis
      .replace(/[^\uAC00-\uD7AF\u1100-\u11FF\u3130-\u318F]/g, ' ') // Replace non-Korean characters with a space
      .replace(/\s+/g, ' ') // Replace multiple consecutive spaces with a single space
      .trim(); // Trim leading and trailing spaces
    if (koreanGraphNm) {
      buttonData.push({
        text: koreanGraphNm,
        value: koreanGraphNm,
      });
    }
  });

  const props = {
    reportDetailData,
    handleBack,
    handleButtonClick,
    activeButton,
    header,
    buttonData,
    isLoading,
  };

  return (
    <>
      <ReportInformationView {...props} />
    </>
  );
};

export default ReportInformation;
