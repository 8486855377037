import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MoreQuestionAnswerListView from './more-question-answer-list.view';
import { EtcApi } from '../../../../apis/v1/etc.api';

import useAuthentication from '../../../../store/useAuthentication';

const MoreQuestionAnswerList = () => {
  const { authentication } = useAuthentication();

  const location = useLocation();
  const navigate = useNavigate();
  const etcApi = new EtcApi(process.env.REACT_APP_ENV !== 'dev', authentication.accessToken, authentication.deviceId);
  const [qnaData, setQnaData] = useState<any>('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (qnaData.length !== 0 && authentication.accessToken.length !== 0) {
      setIsLoading(false);
    }
  }, [qnaData, authentication.accessToken]);
  useEffect(() => {
    if (authentication.accessToken && authentication.deviceId) {
      const fetchData = async () => {
        try {
          const { data }: any = await etcApi.getVocListAsync();
          setQnaData(data);
        } catch (error) {
          // await window.flutter_inappwebview.callHandler('goErrorPage', '');
        }
      };
      fetchData();
    }
  }, [authentication.accessToken, authentication.deviceId]);
  const handleGoBack = () => {
    navigate(-1);
  };

  const handleAskQuestionBtn = () => {
    navigate(`${location.pathname}/ask-question`);
  };

  const props = {
    handleGoBack,
    location,
    handleAskQuestionBtn,
    qnaData,
    isLoading,
  };

  return <MoreQuestionAnswerListView {...props} />;
};

export default MoreQuestionAnswerList;
