import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MoreView from './more.view';
import { EtcApi, GetProfileResponse } from '../../../apis/v1/etc.api';
import useAuthentication from '../../../store/useAuthentication';

const More = () => {
  const [toggleButton, setToggleButton] = useState(true);
  const [isNewVersion, setIsNewVersion] = useState(false);
  const [profileData, setProfileData] = useState<GetProfileResponse>();
  const navigate = useNavigate();

  const { authentication } = useAuthentication();
  const etcApi = new EtcApi(process.env.REACT_APP_ENV !== 'dev', authentication.accessToken, authentication.deviceId);

  const handleToggleBtn = () => {
    setToggleButton((prevState) => !toggleButton);
  };

  useEffect(() => {
    if (authentication.accessToken && authentication.deviceId) {
      const fetchData = async () => {
        try {
          const { data }: any = await etcApi.getProfileAsync();
          setProfileData(data);
        } catch (error) {
          // await window.flutter_inappwebview.callHandler('goErrorPage', '');
        }
      };
      fetchData();
    }
  }, [authentication.accessToken, authentication.deviceId]);

  useEffect(() => {
    const getResponse = async () => {
      if (process.env.REACT_APP_BUILD === 'flutter') {
        const response = await window.flutter_inappwebview.callHandler('showUpdateButton');
        setIsNewVersion(response);
      }
    };
    getResponse();
  }, [window.flutter_inappwebview?.callHandler('showUpdateButton')]);

  const handlePortfolioPageLink = () => {
    navigate('/more/profile');
  };

  const handleUpdateButton = async () => {
    if (process.env.REACT_APP_BUILD === 'flutter') {
      await window.flutter_inappwebview.callHandler('updateToMarket', '');
    }
  };
  const props = {
    handlePortfolioPageLink,
    handleToggleBtn,
    toggleButton,
    profileData,
    handleUpdateButton,
    isNewVersion,
  };
  return <MoreView {...props} />;
};

export default More;
