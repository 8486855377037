import React, {ReactElement, useState} from 'react';
import useDpi from '../../../hooks/useDpi';
import BarChartSectionView from "./bar-chart-section.view";

interface BarChartSectionProps {
    icon?: any;
    moreBtn?: ReactElement<any, any>;
    data?: any;
    iconName?: any;
}

const BarChartSection = ({ icon, moreBtn, data, iconName }: BarChartSectionProps) => {
    const { iconFontSize, secondaryFontSize } = useDpi();
    const [selectedYear, setSelectedYear] = useState(data?.graph?.[0]?.nthYear);

    const groupedData:any = {};

    data?.graph?.forEach((row: any) => {
        if (!groupedData[row.nthYear]) {
            groupedData[row.nthYear] = [];
        }
        groupedData[row.nthYear].push(row);
    });

    const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedYear = parseInt(event.target.value);
        setSelectedYear(selectedYear);
    };

    const props = {
        icon,
        moreBtn,
        groupedData,
        iconName,
        iconFontSize, secondaryFontSize,
        data,
        selectedYear,
        handleYearChange
    }

    return (
       <BarChartSectionView {...props} />
    );
};

export default BarChartSection;
