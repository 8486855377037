import React from 'react';
import TypographyView from '../../atoms/foundation/typography.view';
import { css } from '@emotion/react';
import Colors from '../../../types/colors';

interface SurveyMiddleSectionViewProps {
    headerFontSize: number;
    yearText: string
}
const SurveyMiddleSectionView = ({ headerFontSize, yearText }: SurveyMiddleSectionViewProps) => {
  const wrapper = css`
    //margin: 17px 0 17px 17px;
    display: flex;
    align-items: center;
    padding-left: 21px;
    height: 66px;
    margin-bottom: 0;
    background-color: ${Colors['white_regular']};
  `;


  return (
    <div css={wrapper}>
      <TypographyView fontSize={headerFontSize} bold={true}>
        {yearText}
      </TypographyView>
    </div>
  );
};

export default SurveyMiddleSectionView;
