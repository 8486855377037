import React from 'react';
import { css } from '@emotion/react';
import TypographyView from '../../atoms/foundation/typography.view';
import Colors from '../../../types/colors';
import { Link, useLocation } from 'react-router-dom';
import useDpi from '../../../hooks/useDpi';

interface ReportListViewProps {
  reportPageData: any;
}



const ReportListView = ({ reportPageData }: ReportListViewProps) => {
  const { listTitle } = useDpi();
  const wrapper = css`
    display: flex;
    flex-direction: column;
    background-color: ${Colors['white_regular']};
    //height: calc(100vh - 110px);
  `;

  const row = css`
    display: flex;
    align-items: center;
    //padding-left: 20px;
    background-color: ${Colors['white_regular']};

    border-top: 1px solid ${Colors['Grey_02_line']};
    height: 66px;
    a {
      width: 100vw;
    }
  `;

  const typographyStyle = css`
    display: flex;
    align-items: center;
    vertical-align: center;
    font-size: ${listTitle}px;
    height: 66px;
    padding-left: 20px;
    font-weight: 500;
    color: ${Colors['Grey_05_subtext']};
  `;

  const location = useLocation();
  return (
    <div css={wrapper}>
      {reportPageData?.dataList?.map((value: { surveyType: string; surveyName: string }, index: number) => (
        <div key={index} css={row}>
          <Link key={value.surveyName} to={`${location.pathname}/${value.surveyType}&${value.surveyName}`} style={{ textDecoration: 'none' }}>
            <TypographyView cssStyles={typographyStyle} color={'Grey_05_subtext'}>
              {index + 1}.&nbsp;{value.surveyName}
            </TypographyView>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default ReportListView;
