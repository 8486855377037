import React, { useEffect, useState } from 'react';
import ReportPageView from './report-page.view';
import { ReportAPI } from '../../../apis/v1/report.api';
import useAuthentication from '../../../store/useAuthentication';

const ReportPage = () => {
  const { authentication } = useAuthentication();
  const reportApi = new ReportAPI(process.env.REACT_APP_ENV !== 'dev', authentication.accessToken, authentication.deviceId);
  const [reportPageData, setReportPageData] = useState([]);

  useEffect(() => {
    if (authentication.accessToken && authentication.deviceId) {
      const fetchData = async () => {
        try {
          const { data } = await reportApi.getSurveysAsync();
          setReportPageData(data);
        } catch (error) {
          // await window.flutter_inappwebview.callHandler('goErrorPage', '');
        }
      };
      fetchData();
    }
  }, [authentication.accessToken, authentication.deviceId]);
  const props = {
    reportPageData,
  };

  return <ReportPageView {...props} />;
};

export default ReportPage;
