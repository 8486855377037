import React, { useState } from 'react';
import SurveyBodySectionView from './survey-body-section.view';
import { useNavigate } from 'react-router-dom';

interface SurveyBodySectionProps {
  surveyPageData: any;
}

const SurveyBodySection = ({ surveyPageData }: SurveyBodySectionProps) => {
  const [lastDisabledIndex, setLastDisabledIndex] = useState(-1);
  const navigate = useNavigate();
  const handleClick = async (surveyId: number) => {
    // redirect to survey page
    if(process.env.REACT_APP_BUILD === "flutter"){
      const response = await window.flutter_inappwebview.callHandler('showReSurvey');
      if (response) {
        // @ts-ignore
        await window.flutter_inappwebview.callHandler('openContinueSurvey', surveyId);
      }
    }else {
      navigate(`https://chc-app-api.huraydev.net/v1/survey/${surveyId}`);
    }
  };

  const props = {
    handleClick,
    lastDisabledIndex,
    surveyPageData,
  };

  return <SurveyBodySectionView {...props} />;
};

export default SurveyBodySection;
