import React from 'react';
import TypographyView from "../foundation/typography.view";
import {css} from "@emotion/react";



interface TableData {
    data?:any,
    selectedYear:any
}

const TableC = ({data,selectedYear}:TableData) => {
    const typography = (color?: string) => css`
      display: flex;
      margin: 35px auto;
      justify-content: center;
      color: ${color};
    `

    return <div>
        {data[selectedYear]?.map((value:any, index:number) => {
            const updatedText = value.value.replace(/(\p{Script=Hangul})(\()/gu, '$1 $2');
            return <TypographyView key={index} cssStyles={typography(value?.color)} children={updatedText}/>
        })}
    </div>

};

export default TableC;
