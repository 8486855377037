import React, { useEffect, useState } from 'react';
import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import QuestionAndAnswer from './components/molecules/dashboard/alarm/question-and-answer';
import Messages from './components/molecules/dashboard/alarm/messages';
import MoreMessageList from './components/organisms/more/messages/more-message-list';
import ErrorPage from './components/pages/ErrorPage/error-page';
import MoreQuestionDetail from './components/organisms/more/questions/questionDetail/question-detail';
import DashboardAlarm from './components/organisms/dashboard/alarm/dashboard-alarm';
import SurveyPage, { SignInResponse } from './components/pages/Survey/survey-page';
import ReportPage from './components/pages/Report/report-page';
import MoreProfile from './components/organisms/more/profile/more-profile';
import ReportInformation from './components/organisms/report/reportInformationPage/report-information';
import MorePage from './components/pages/More/more-page';
import MoreTermsDetail from './components/organisms/more/terms/termsDetail/more-terms-detail';
import DashboardPage from './components/pages/Dashboard/dashboard-page';
import MoreTerms from './components/organisms/more/terms/more-terms';
import AskQuestion from './components/organisms/more/questions/askQuestion/ask-question';
import MoreVersion from './components/organisms/more/version/more-version';
import SelectedMessage from './components/organisms/more/messages/selectedMessage/selected-message';
import MoreQuestionAnswerList from './components/organisms/more/questions/more-question-answer-list';
import {AccountAPI, PostSignInResponse} from './apis/v1/account.api';
import useAuthentication from './store/useAuthentication';
import { AxiosResponse } from 'axios';
import Colors from "./types/colors";
interface notificationType{
  id:string,
  type:string,
  content:string,
}
function App() {
  ///////////////////////////////////////////////////////////////////////
  const { authentication, setAuthentication } = useAuthentication();
  const navigate = useNavigate();
  const toRoute = async (notification:notificationType)  => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('pageNumber');
    if (id === '1') {
      navigate('/survey');
    }
    if (id === '2') {
      navigate('/report');
    }
    if (id === '3') {
      navigate('/more');
    }

    if(notification?.type?.toLowerCase() === 'expired'){
      return await window.flutter_inappwebview.callHandler('goRefresh', '');
    }
    if(notification?.type?.toLowerCase() === 'push_voc_answer'){
      navigate(`more/question-answer/${notification?.id}`);
    }
    if(notification?.type?.toLowerCase() === 'push_message'){
      navigate(`more/messages/${notification?.id}`);
    }
    if(notification?.type?.toLowerCase() === 'survey_ended'){
      navigate('/survey');
    }
    if(notification?.type?.toLowerCase() === 'survey_ended'){
      navigate('/survey');
    }

  };
  useEffect(() => {
    (async () => {
      if (process.env.REACT_APP_BUILD === 'flutter') {
        let result;
        let info;
         let notificationType;

        try {
          if (window.flutter_inappwebview.callHandler) {
            result = await window.flutter_inappwebview.callHandler('requestToken', '테스트 핸들러');
            info = await window.flutter_inappwebview.callHandler('requestDeviceInfo', '테스트 핸들러');
            notificationType = await window.flutter_inappwebview.callHandler('notificationPush', 'notificationPush');
          } else {
            // @ts-ignore
            result = await window.flutter_inappwebview.callHandler('requestToken', '테스트 핸들러');
            // @ts-ignore
            info = await window.flutter_inappwebview.callHandler('requestDeviceInfo', '테스트 핸들러');
          }
        } catch (e) {
          alert(e);
        }
        // todo: device id 전역 상태에 설정
        setAuthentication({
          accessToken: result,
          deviceId: info !== undefined ? info[3] : 'test',
          appVersion: info !== undefined ? info[0] : '1.0.0',
        });
        const jsonData:notificationType = JSON.parse(notificationType);
        await toRoute(jsonData);
      }
    })();
    // 안준수6
    const fetchData = async () => {
      try {
        if (process.env.REACT_APP_BUILD !== 'flutter') {
          const deviceId = '123123';
          const accountApi = new AccountAPI(process.env.REACT_APP_ENV !== 'dev', undefined, deviceId);

          const { data, status } = (await accountApi.postSignInAsync({
            body: {
              userName: '고동균4',
              userBirthDt: "1987-08-06",
              userGender: "M",
              userCpTelNo: "01093733408"
            },
            header: {
              AppVersion: '1.0',
              OsType: 'android',
              PushToken: 'string',
            },
          })) as AxiosResponse<PostSignInResponse, any>;
          setAuthentication({
            accessToken: data.tokenInfoDto.accessToken,
            deviceId: deviceId,
          });
        }
      } catch (error) {
        // alert(error);
        console.error(error);
      }
    };

    fetchData();
  }, []);
  ///////////////////////////////////////////////////////////////////////
  const location = useLocation();
  const [transitionDirection, setTransitionDirection] = useState<'in' | 'out'>('in');
  const [previousPathname, setPreviousPathname] = useState('');


  useEffect(() => {
    const isChild = location.pathname.split('/').length > 2;

    if (isChild) {
      setTimeout(() => {
        setTransitionDirection('out');
      }, 400);
    } else {
      setTimeout(() => {
        setTransitionDirection('in');
      }, 400);
    }
    setPreviousPathname(location.pathname);
  }, [location, previousPathname]);

  function handleLinkClick(e: React.MouseEvent<HTMLAnchorElement>) {
    const linkPathname = e.currentTarget.pathname;

    if (linkPathname === location.pathname) {
      e.preventDefault();
    }
  }

  const isHomePage = location.pathname === '/index.html' || location.pathname === '/' || location.pathname === '/survey';


  return (
    <div className="App">
      {
          process.env.REACT_APP_VERSION_DISPLAY &&  <span  style={{
            backgroundColor: `${isHomePage  ? Colors.Primary_100_basic: 'inherit'}`,
            borderRadius: 7,
            height: 'auto',
            color:`${isHomePage  ? 'white': 'black'}`,
            position: 'absolute',
            zIndex: 999,
            padding: '0 5px',
            margin: 8,
            marginTop: 50,
            top: 0,
            fontSize: '13px',
            right: !isHomePage ? 0 : 'auto',
          }}>
        v{process.env.REACT_APP_BUILD === 'flutter' ? authentication.appVersion: '0.1.0'}
      </span>
      }
      <nav
        style={{
          width: '100%',
          height: 'auto',
          color: 'white',
          position: 'fixed',
          zIndex: 999,
          padding: 0,
          margin: 0,
          bottom: 0,
          display: process.env.REACT_APP_BUILD === 'flutter' ? 'none' : 'inline',
        }}>
        <ul
          style={{
            padding: 0,
            margin: 0,
            display: 'flex',
            justifyContent: 'space-around',
            textDecoration: 'none',
          }}>
          <li style={{ textDecoration: 'none' }}>
            <Link to="/index.html" onClick={handleLinkClick}>
              dash
            </Link>
          </li>
          <li style={{ textDecoration: 'none' }}>
            <Link to="/survey" onClick={handleLinkClick}>
              survey
            </Link>
          </li>
          <li style={{ textDecoration: 'none' }}>
            <Link to="/report" onClick={handleLinkClick}>
              report
            </Link>
          </li>{' '}
          <li style={{ textDecoration: 'none' }}>
            <Link to="/more" onClick={handleLinkClick}>
              more
            </Link>
          </li>
        </ul>
      </nav>

      <Routes location={location}>
        <Route path="/" element={<DashboardPage />} />
        <Route path="/index.html" element={<DashboardPage />} />
        <Route path="/survey" element={<SurveyPage />} />
        <Route path="/report" element={<ReportPage />} />
        <Route path="/more" element={<MorePage />} />
        <Route path="/dashboard/alarm" element={<DashboardAlarm />}>
          <Route path={'messages'} element={<Messages />} /> <Route path={'qna'} element={<QuestionAndAnswer />} />
        </Route>
        <Route path={'report/:surveyType'} element={<ReportInformation />} />
        <Route path={'more/messages'} element={<MoreMessageList />} />
        <Route path={'more/messages/:id'} element={<SelectedMessage />} />
        <Route path={'more/profile'} element={<MoreProfile />} />
        <Route path={'more/question-answer'} element={<MoreQuestionAnswerList />} />
        <Route path={'more/question-answer/:id'} element={<MoreQuestionDetail />} />
        <Route path={'more/question-answer/ask-question'} element={<AskQuestion />} />
        <Route path={'more/terms'} element={<MoreTerms />} />
        <Route path={'more/terms/:terms'} element={<MoreTermsDetail />} />
        <Route path={'more/version'} element={<MoreVersion />} />
        <Route path={'*'} element={<ErrorPage />} />
      </Routes>
    </div>
  );
}

export default App;
