import {
  GraphAnxietyDepressed,
  GraphHealth,
  GraphDiet,
  GraphStatistics,
  GraphIntestine,
  GraphMotivation,
  GraphGender,
} from './svg';
interface IconMap {
  [key: string]: string;
}
let iconMap: IconMap = {
  CRC_G_DNA: GraphDiet,
  CRC_G_CHA: GraphHealth,
  CRC_G_CHB: GraphHealth,
  CRC_G_SMA: GraphMotivation,
  CRC_G_SMB: GraphMotivation,
  CRC_GD_CHC: GraphHealth,
  CRC_GD_CHD: GraphHealth,
  CRC_GD_SMC: GraphMotivation,
  CRC_GD_SMD: GraphMotivation,
  CRC_GD_SME: GraphMotivation,
  CRC_GD_HEA: GraphHealth,
  CRC_GD_ADA: GraphAnxietyDepressed,
};

export default iconMap;
